@import 'breakpoints';
@import 'colors';

.ant-layout-header {
  display: flex;
  justify-content: space-between;
  background: $header-background-color;
  padding: 0 0.1em !important;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  z-index: 1;

  .menu-item:hover,
  .brand:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }

  .logo {
    height: 45px;
    width: auto;
  }

  .right-content {
    display: flex;

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .menu-item {
    width: 52px;
    text-align: center;

    .icon-avatar {
      color: $primary-color;
      background: transparentize($primary-color, 0.9);
    }
  }

  .button {
    width: 72px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
  }
}

.ant-page-header div:first-child.ant-page-header-title-view .ant-page-header-title-view-title {
  line-height: 2;
}

.user-menu.ant-dropdown-menu .ant-dropdown-menu-item .anticon {
  margin-right: 8px;
}

.ant-layout-header .menu-item {
  width: auto;
}

.header-content {
  padding: 0 2em;
  z-index: 10;
}

.header-middle {
  justify-content: center;
  align-items: center;
}
