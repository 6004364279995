@import "colors";
@import "fonts";
@import "breakpoints";
@import "layout";
@import "header";
@import "tabs";

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}
